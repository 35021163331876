






















































































































































































































































































































































































































































































































































































































import {
  ref,
  reactive,
  toRefs,
  computed,
  defineComponent,
  Ref,
  onMounted,
  watch,
  toRef
} from '@vue/composition-api';
import { ObjectId } from 'bson';
import axios from 'axios';
import _ from 'lodash';
import { useUserState, useUserActions, useProgramActions, useProgramState } from '@/store';
import Bar from '@/views/Explore/Sidebar.vue';
import ListView from '@/views/Explore/components/TableView.vue';
import ATextInput from '@/components/atoms/ATextInput.vue';

export default defineComponent({
  name: 'ExploreGuide',
  components: {
    'guide-bar': Bar,
    ListView,
    ATextInput
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: ''
    },
    shareCode: {
      type: String,
      required: false,
      default: ''
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    preSelectedTemplate: {
      type: String,
      default: null
    },
    showSettingsDialogBox: {
      type: Boolean,
      required: false,
      default: false
    },
    showSaveSettings: {
      type: Boolean,
      required: false,
      default: false
    },
    templateListValue: {
      type: Array,
      required: false,
      default: () => []
    },
    pathwaysListValue: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props: any, ctx: any) {
    const { emit, attrs } = ctx;
    const { user }: any = useUserState(['user']);
    const employerData: Ref<any[]> = ref([]);
    const { fetchTemplates, fetchPathways, fetchAllPublishedPrograms, saveSearchPreference } =
      useProgramActions([
        'fetchTemplates',
        'fetchPathways',
        'fetchAllPublishedPrograms',
        'saveSearchPreference'
      ]);
    const { templateList }: any = useProgramState(['templateList']);
    const { getVisitedStudents } = useUserActions(['getVisitedStudents']);
    const state = reactive({
      programFilter: 'All' as 'All' | 'Bookmarked',
      ageFilter: null as any,
      residenceFilter: null,
      pathwaysFilter: [{} as any],
      bookmarked: [] as ObjectId[],
      residenceOptions: [] as string[]
    });
    const skip = ref(0);
    const limit = ref(100);
    const isFetching = ref(false);
    const pathwaysList = ref([]);
    const allProgramsFetched = ref(false);
    const categories = ref(pathwaysList.value);
    const selectedPathways = ref([]);
    const selectedIndustries = ref([]);
    const isSearching = ref(false);
    const currentTemplate: any = ref({});
    const colors = ['orange', 'red', 'blue', 'green', 'pink', 'yellow', 'purple', 'orange'];
    const sidebar = ref();
    const isSearched = ref(false);
    const isSidebar = ref(true);
    const searchValue = ref();
    const pathwaysDataValue = ref();
    const isAddClass = ref(false);
    const search = ref('');
    const selectedEmployerList: any = ref([]);
    const isSaveSelectedProgramLoading = ref(false);
    const templatesToShow = ref(false);
    const showSettingsDialog = ref(false);
    const showSettingsDialogBoxRef = toRef(props, 'showSettingsDialogBox');
    const showSaveSettingsRf = toRef(props, 'showSaveSettings');
    const templateIcons = {
      All: 'mdi-view-grid',
      'All Public Programs': 'mdi-view-earth',
      'Your Sponsor’s Programs': 'mdi-view-license',
      'Build projects to win internships': 'mdi-trophy-award',
      'Build projects for simulated internships': 'mdi-laptop',
      'Build projects for your portfolio': 'mdi-briefcase-account',
      'Brainstorm project ideas': 'mdi-lightbulb-on',
      'Solve real-world problems': 'mdi-fire',
      'Build projects to pitch internships': 'mdi-presentation',
      'Build projects to build businesses': 'mdi-domain',
      'Build projects for a better world': 'mdi-earth',
      Workshops: 'mdi-hammer-wrench',
      'Build skills for resilient communities': 'mdi-shield-home'
    };

    const templateColors = {
      All: 'orange',
      'All Public Programs': 'orange',
      'Your Sponsor’s Programs': 'orange',
      'Build projects to win internships': 'red',
      'Build projects for simulated internships': 'blue',
      'Build projects for your portfolio': 'green',
      'Brainstorm project ideas': 'pink',
      'Solve real-world problems': 'yellow',
      'Build projects to pitch internships': 'purple',
      'Build projects to build businesses': 'green',
      'Build projects for a better world': 'yellow',
      Workshops: 'orange',
      'Build skills for resilient communities': 'green'
    };

    const templateDurations = {
      'Build projects to win internships': '1+ Month',
      'Build projects to pitch internships': '2+ Weeks',
      'Build projects to build businesses': '1+ Month',
      'Build projects for a better world': '1+ Month',
      'Build projects for simulated internships': '2+ Week',
      'Build projects for your portfolio': '1+ Week',
      'Brainstorm project ideas': '1+ Hour',
      'Solve real-world problems': '1+ Minute',
      Workshops: '1+ Minute',
      'Build skills for resilient communities': '1+ Week'

      // Add more durations as needed
    };

    const getTemplateIcon = (templateName: string) => {
      return templateIcons[templateName] || 'mdi-telegram';
    };

    const getTemplateColor = (templateName: string) => {
      return templateColors[templateName] || 'grey darken-3';
    };

    const getTemplateDuration = (templateName: string) => {
      return templateDurations[templateName] || null;
    };

    // Move this function definition to the top of the setup function
    const updateURLWithPathways = selectedPathways => {
      const currentRoute = ctx.root.$route;
      const newQuery = { ...currentRoute.query };
      if (currentTemplate.value) {
        newQuery.program = currentTemplate.value.templateName;
      }

      if (currentTemplate.value.templateName !== 'All') {
        newQuery.model = currentTemplate.value._id;
      } else {
        delete newQuery.model;
      }
      if (selectedPathways.length > 0) {
        newQuery.pathway = selectedPathways.map(p => p.name);
      } else {
        delete newQuery.pathway;
      }
      ctx.root.$router
        .push({
          path: currentRoute.path,
          query: newQuery
        })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
    };

    function getAllPrograms(val) {
      const payload = {
        skip: skip.value,
        limit: limit.value
      };

      // Add templateId if specific program model selected
      if (val.templateName !== 'All') {
        payload.templateId = val?._id?.toString();
      }
      if (val.templateName === 'Your Sponsor’s Programs') {
        if (
          props?.shareCode &&
          localStorage.getItem('sponsorAllData') &&
          JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings &&
          JSON.parse(localStorage.getItem('sponsorAllData'))?.sponsorUserId &&
          JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings
            ?.includeCreatedPrograms
        ) {
          payload.userId = JSON.parse(localStorage.getItem('sponsorAllData')).sponsorUserId;
        }
      }

      if (selectedPathways?.value?.length) {
        payload.pathwayList = JSON.stringify(selectedPathways.value.map(s => s.name));
      }
      if (
        props?.shareCode &&
        localStorage.getItem('sponsorAllData') &&
        JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings &&
        JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings
          .displayAllPublicPrograms === true
      ) {
        payload.publicPrograms = true;
      } else if (
        props?.shareCode &&
        localStorage.getItem('sponsorAllData') &&
        JSON.parse(localStorage.getItem('sponsorAllData')).sponsorUserId
      ) {
        payload.userId = JSON.parse(localStorage.getItem('sponsorAllData')).sponsorUserId;
      }
      if (selectedIndustries?.value?.length) {
        payload.industryList = JSON.stringify(selectedIndustries.value.map(s => s.name));
      }

      if (searchValue?.value) {
        payload.keyword = searchValue?.value;
      }
      if (props?.userId) {
        payload.userId = props?.userId;
      }
      employerData.value = [];
      const data = { pathways: pathwaysDataValue?.value, payload };
      fetchAllPublishedPrograms(data)
        .then(async response => {
          let visitedStudents = [];
          if (localStorage.getItem('apollo-token')) {
            visitedStudents = await getVisitedStudents();
          }
          if (response?.sponsorProgramIds) {
            selectedEmployerList.value = response?.sponsorProgramIds;
          }
          employerData.value = [];
          if (response?.programs.length) {
            response?.programs.forEach(element => {
              employerData.value.push(element);
            });
            if (visitedStudents && visitedStudents.length >= 1) {
              const programIds = visitedStudents.map(student => student.program_id);
              employerData.value.forEach((element, index) => {
                if (programIds.includes(element._id)) {
                  employerData.value[index].viewed = true;
                }
              });
            }
            const searchPayload = payload;
            delete searchPayload.skip;
            delete searchPayload.limit;
            if (
              skip.value === 0 &&
              (searchPayload?.pathwayList?.length >= 1 ||
                searchPayload?.industryList?.length >= 1 ||
                searchPayload?.keyword)
            ) {
              saveSearchPreference({
                filters: {
                  templateId: searchPayload?.templateId,
                  pathways: searchPayload?.pathwayList,
                  industries: searchPayload?.industryList,
                  keyword: searchPayload?.keyword
                },
                search_result: response?.programs?.map(program => ({
                  _id: program?._id?.toString(),
                  programName: program?.programName,
                  createdBy:
                    program?.createdBy?.toString() || program?.organizers[0]?.userId?.toString()
                })),
                user_id: user?.value?._id?.toString(),
                email: user?.value?.email?.toString(),
                phone_number: user?.value?.phoneNumber?.toString()
              });
            }
            isFetching.value = false;
          } else {
            allProgramsFetched.value = true;
            isFetching.value = false;
          }
        })
        .catch(error => console.log(error));
    }
    const refTemplates = [{ templateName: 'All', value: 'all' }];
    if (
      props?.shareCode &&
      localStorage.getItem('sponsorAllData') &&
      JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings &&
      JSON.parse(localStorage.getItem('sponsorAllData'))?.sponsorUserId &&
      JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings?.includeCreatedPrograms
    ) {
      refTemplates.push({ templateName: 'Your Sponsor’s Programs', value: 'sponsored_programs' });
    }
    const templates = ref(refTemplates);
    const updateSelectedEmployerList = id => {
      const arrayIndex = selectedEmployerList.value.indexOf(id);
      if (arrayIndex !== -1) {
        selectedEmployerList.value.splice(arrayIndex, 1);
      } else {
        selectedEmployerList.value.push(id);
      }
    };

    const updateURL = template => {
      const currentRoute = ctx.root.$route;
      const newQuery = {
        ...currentRoute.query,
        program: template.templateName
      };

      if (template.templateName !== 'All') {
        newQuery.model = template._id;
      } else {
        delete newQuery.model;
      }

      // Remove the old 'template' and 'templateId' query parameters if they exist
      delete newQuery.template;
      delete newQuery.templateId;

      ctx.root.$router
        .push({
          path: currentRoute.path,
          query: newQuery
        })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
    };

    const loadTemplate = (template, notUpdateUrl = false) => {
      isSearched.value = false;
      currentTemplate.value = template;
      skip.value = 0;
      searchValue.value = '';
      employerData.value = [];
      selectedPathways.value = [];
      selectedIndustries.value = [];
      getAllPrograms(currentTemplate.value);

      // Update URL
      if (notUpdateUrl === false) {
        updateURL(template);
      }
    };
    const processedTemplates = computed(() => {
      return props.templateListValue.map(element => element);
    });
    // Now onMounted can use loadTemplate
    onMounted(async () => {
      // const templateListValue: any = await fetchTemplates();
      // console.log('>>>>>', templateListValue);
      // templateListValue?.forEach(element => {
      //   templates.value.push(element);
      // });
      const pathwaysData: any = await fetchPathways();
      pathwaysDataValue.value = pathwaysData?.pathways;

      const urlPathways = ctx.root.$route.query.pathway;
      if (urlPathways) {
        const pathwayNames = Array.isArray(urlPathways) ? urlPathways : [urlPathways];
        selectedPathways.value = pathwaysList.value.filter(p => pathwayNames.includes(p.name));
      }
    });

    watch(
      () => props.templateListValue,
      newValue => {
        if (Array.isArray(newValue)) {
          newValue?.forEach(element => {
            templates.value.push(element);
            const urlProgram = ctx.root.$route.query.program;
            const urlModel = ctx.root.$route.query.model;
            const isSponsorRoute = ctx.root.$route.path.startsWith('/sponsor/');
            const getProgramSettings = localStorage.getItem('programSettings');
            let defaultTabOpen = 'All';
            let defaultSettings = '';
            if (getProgramSettings) {
              defaultSettings = JSON.parse(getProgramSettings);
              defaultTabOpen =
                defaultSettings.selectedProgramModels.length > 0
                  ? defaultSettings.selectedProgramModels[0]
                  : 'All';
            }
            console.log('length', templates.value.length, newValue.length);
            if (newValue.length <= templates.value.length + 1) {
              if (urlProgram === 'All') {
                const defaultTemplate = templates.value.find(data => {
                  return (
                    data?.templateName?.trim().toLowerCase() ===
                    (isSponsorRoute ? defaultTabOpen : 'All').toLowerCase()
                  );
                });
                currentTemplate.value = isSponsorRoute ? defaultTemplate : templates.value[0];
              } else if (urlProgram && urlModel && !isSponsorRoute) {
                const selectedTemplate = templates.value.find(
                  (t: any) => t?.templateName === urlProgram && t._id === urlModel
                );
                if (selectedTemplate) {
                  currentTemplate.value = selectedTemplate;
                } else {
                  [currentTemplate.value] = templates.value;
                }
              } else {
                let defaultTabOpen = 'All';
                if (getProgramSettings) {
                  const defaultSettings = JSON.parse(getProgramSettings);
                  defaultTabOpen =
                    defaultSettings.selectedProgramModels.length > 0
                      ? defaultSettings.selectedProgramModels[0]
                      : 'All';
                  defaultTabOpen = defaultSettings.defaultProgramModel
                    ? defaultSettings.defaultProgramModel
                    : defaultTabOpen;
                }
                const defaultTemplate = templates.value.find(data => {
                  return (
                    data?.templateName?.trim().toLowerCase() ===
                    (isSponsorRoute
                      ? defaultTabOpen
                      : 'Build projects to win internships'
                    ).toLowerCase()
                  );
                });
                console.log(defaultTemplate);
                currentTemplate.value = defaultTemplate;
              }
            }
            if (isSponsorRoute && !defaultSettings.selectedProgramModels.includes('All')) {
              const index = templates.value.findIndex(template => template.templateName === 'All');
              if (index !== -1) {
                templates.value.splice(index, 1); // Remove the object if it exists
              }
            }
            if (
              isSponsorRoute &&
              !defaultSettings.selectedProgramModels.includes('Your Sponsor’s Programs')
            ) {
              const index = templates.value.findIndex(
                template => template.templateName === 'Your Sponsor’s Programs'
              );
              if (index !== -1) {
                templates.value.splice(index, 1);
              }
            }
          });
        }
      },
      { immediate: true } // Trigger immediately when the component is mounted
    );

    function saveSelectedProgram() {
      return new Promise((resolve, reject) => {
        isSaveSelectedProgramLoading.value = true;
        const API_ENDPOINT = process.env.VUE_APP_SAVE_PROGRAM_LIST;
        const data = {
          programIds: selectedEmployerList.value,
          shareCode: props.shareCode
        };
        axios
          .post(`${API_ENDPOINT}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          })
          .then(async res => {
            isSaveSelectedProgramLoading.value = false;
            resolve(res);
          })
          .catch(error => {
            isSaveSelectedProgramLoading.value = false;
            console.log(error);
            reject(error);
          });
      });
    }

    const savedBookmarks = localStorage.getItem('bookmarkedPrograms'); // * grab bookmarks from localStorage
    if (savedBookmarks) state.bookmarked = JSON.parse(savedBookmarks).map(id => new ObjectId(id));

    if (ctx.root.$route.query) {
      let query = ctx.root.$route.query.pathway;
      if (!Array.isArray(query)) query = [query];
      query = query.filter(word => !!word);
      if (!query.length)
        state.pathwaysFilter = [
          {
            text: 'All',
            color: 'grey darken-4'
          }
        ];
      else
        state.pathwaysFilter = query.map(pathway => ({
          text: pathway as string,
          color: 'grey darken-4'
        }));
    }

    const currentUnit = ref(ListView);

    const filteredPrograms = computed(() => {
      let visiblePrograms;
      if (state.programFilter === 'Bookmarked') {
        visiblePrograms = employerData.value.filter((program: Record<string, any>) =>
          state.bookmarked.some((id: ObjectId) => id.equals(program._id))
        );
      } else {
        visiblePrograms = employerData.value;
      }

      if (state.residenceFilter && state.residenceFilter !== 'None') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.requiredResidency && program.requiredResidency.includes(state.residenceFilter)
        );
      }

      if (state.ageFilter && state.ageFilter !== 'All') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.ageRange[0] <= parseInt(state.ageFilter, 10) &&
            program.ageRange[1] >= parseInt(state.ageFilter, 10)
        );
      }

      if (state.pathwaysFilter.length && !state.pathwaysFilter.some(obj => obj.text === 'All')) {
        const filters = state.pathwaysFilter.map(obj => obj.text);
        visiblePrograms = visiblePrograms.filter(
          program => program.pathways && program.pathways.some(pathway => filters.includes(pathway))
        );
      }
      return visiblePrograms;
    });

    const bookmarkProgram = (programId: ObjectId) => {
      if (state.bookmarked.some((id: ObjectId) => id === programId))
        state.bookmarked = state.bookmarked.filter((id: ObjectId) => id === programId);
      else state.bookmarked.push(programId);
      localStorage.setItem('bookmarkedPrograms', JSON.stringify(state.bookmarked)); //* save bookmarks to localStorage
    };

    function filterPathways(val, notUpdateUrl = false) {
      isSearched.value = false;
      skip.value = 0;
      employerData.value = [];
      if (!selectedPathways.value.includes(val)) {
        selectedPathways.value.push(val);
      } else {
        const index = selectedPathways.value.findIndex(s => s === val);
        selectedPathways.value.splice(index, 1);
      }
      getAllPrograms(currentTemplate.value);
      updateURLWithPathways(selectedPathways.value);
    }

    watch(
      () => props.pathwaysListValue,
      newValue => {
        if (Array.isArray(newValue)) {
          newValue.forEach(elementPath => {
            const isDuplicate = pathwaysList.value.some(
              item => JSON.stringify(item) === JSON.stringify(elementPath)
            );
            // Only add if not a duplicate
            if (!isDuplicate) {
              pathwaysList.value.push(elementPath);
            }
          });
          const urlPathways = ctx.root.$route.query.pathway;
          const isSponsorRoute = ctx.root.$route.path.startsWith('/sponsor/');
          if (urlPathways) {
            const pathwayNames = Array.isArray(urlPathways) ? urlPathways : [urlPathways];
            selectedPathways.value = pathwaysList.value.filter(p => pathwayNames.includes(p.name));
          }
          let defaultTabOpen = '';
          const getProgramSettings = localStorage.getItem('programSettings');
          if (getProgramSettings) {
            defaultTabOpen = JSON.parse(getProgramSettings);
            defaultTabOpen = defaultTabOpen.defaultPathways ? defaultTabOpen.defaultPathways : '';
            if (isSponsorRoute && defaultTabOpen) {
              const pathwayNames = Array.isArray(defaultTabOpen)
                ? defaultTabOpen
                : [defaultTabOpen];
              selectedPathways.value = pathwaysList.value.filter(p =>
                pathwayNames.includes(p.name)
              );
            }
          }
          if (selectedPathways.value) {
            getAllPrograms(currentTemplate.value);
            updateURLWithPathways(selectedPathways.value);
          }
        }
      },
      { immediate: true } // Trigger immediately when the component is mounted
    );

    const searchProgram = _.debounce(async val => {
      try {
        isFetching.value = true;
        isSearching.value = true;
        searchValue.value = val;
        skip.value = 0;
        employerData.value = [];
        getAllPrograms(currentTemplate.value);
        isFetching.value = false;
        isSearching.value = false;
      } catch (err) {
        isFetching.value = false;
        isSearching.value = false;
      }
    }, 500);

    // async function loadMorePrograms() {
    //   skip.value += 9;
    //   if (isSearched.value) {
    //     await searchProgram(sidebar.value?.search);
    //   } else {
    //     await getAllPrograms(currentTemplate.value);
    //   }
    // }

    window.onscroll = async () => {
      const bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      if (bottomOfWindow && !allProgramsFetched.value) {
        getAllPrograms(currentTemplate.value);
      }
    };

    function reloadPrograms() {
      isSearched.value = false;
      skip.value = 0;
      employerData.value = [];
      searchValue.value = '';
      getAllPrograms(currentTemplate.value);
    }

    function filterIndustry(val) {
      isSearched.value = false;
      skip.value = 0;
      employerData.value = [];
      selectedIndustries.value = val;
      getAllPrograms(currentTemplate.value);
    }
    const isSponsorRoute = computed(() => {
      return ctx.root.$route.path.startsWith('/sponsor/');
    });
    function resetFilters() {
      isSearched.value = false;
      currentTemplate.value = templates.value.find(
        template =>
          template?.templateName ===
          (isSponsorRoute.value ? 'All' : 'Build projects to win internships')
      );
      skip.value = 0;
      searchValue.value = '';
      employerData.value = [];
      selectedPathways.value = [];
      selectedIndustries.value = [];
      getAllPrograms(currentTemplate.value);
    }

    function toggleSidebar(val) {
      isSidebar.value = val;
    }

    function resetSearch() {
      search.value = '';
    }
    watch(search, val => {
      if (val.length > 2) {
        searchProgram(val);
      }

      if (!val.length) {
        reloadPrograms();
      }
    });
    watch(
      () => ctx.root.$route.query.template,
      newTemplate => {
        if (newTemplate) {
          const selectedTemplate = templates.value.find(
            t => t.templateName === newTemplate || t._id === newTemplate
          );
          console.log('selcted template', selectedTemplate);
          if (selectedTemplate && selectedTemplate !== currentTemplate.value) {
            loadTemplate(selectedTemplate);
          }
        }
      }
    );
    watch(showSettingsDialogBoxRef, newVal => {
      if (newVal) {
        showSettingsDialog.value = true;
        emit('update:showSettingsDialogBox', false);
      }
    });

    watch(showSaveSettingsRf, newVal => {
      if (newVal) {
        saveSelectedProgram();
        emit('update:showSaveSettings', false);
      }
    });

    const priorityOrder = [
      'All',
      'All Public Programs',
      'Your Sponsor’s Programs',
      'Build projects to win internships',
      'Build projects to pitch internships',
      'Build projects to build businesses',
      'Build projects for a better world',
      'Build skills for resilient communities',
      'Build projects for simulated internships',
      'Build projects for your portfolio',
      'Brainstorm project ideas',
      'Solve real-world problems',
      'Workshops'
    ];

    const sortedTemplates = computed(() => {
      const prioritized = [];
      const remaining = [];

      templates.value.forEach(template => {
        const index = priorityOrder.indexOf(template.templateName);
        if (index !== -1) {
          prioritized[index] = template;
        } else {
          remaining.push(template);
        }
      });

      // Remove any undefined entries (in case a priority template doesn't exist)
      const filteredPrioritized = prioritized.filter(Boolean);

      // Sort remaining templates alphabetically
      remaining.sort((a, b) => a.templateName.localeCompare(b.templateName));

      const filteredData = [...filteredPrioritized, ...remaining];
      return filteredData;
    });

    const existingEmployerDomains = ref([
      'google.com',
      'microsoft.com',
      'apple.com'
      // Add any default/existing domains here
    ]);

    const settings = reactive({
      includeCreatedPrograms: false,
      includeSponsoredPrograms: true,
      includeSelfCreationOfPrograms: false,
      displayAllPublicPrograms: false,
      includeChangeTitleText: true,
      landingTitleText: 'Explore Programs',
      preselectEmployerOptions: false,
      employerDomains: [],
      insertCustomScope: false,
      customScopeText: '',
      filterProgramModels: false,
      selectedProgramModels: sortedTemplates.value.map(t => t.templateName),
      filterPathways: false,
      selectedPathways: pathwaysList.value.map(p => p.name),
      changeDefaultTab: false,
      defaultTab: 'why-pilotcity',
      toggleTabs: false,
      enabledTabs: [],
      landingSponsorFirstName: '',
      landingSponsorLastName: '',
      landingOrganizationName: '',
      type: 'organization'
    });

    const initialSettings = ref(JSON.stringify(settings));

    const hasUnsavedChanges = computed(() => {
      return JSON.stringify(settings) !== initialSettings.value;
    });

    const selectAllTabs = () => {
      settings.enabledTabs = ['why-pilotcity', 'onboard', 'join-programs', 'create-program'];
    };

    const deselectAllTabs = () => {
      settings.enabledTabs = [];
    };

    onMounted(() => {
      const sponsorAllData = localStorage.getItem('sponsorAllData');
      if (sponsorAllData) {
        const getSponsorData = JSON.parse(sponsorAllData);
        settings.landingOrganizationName = getSponsorData?.name;
        settings.landingSponsorFirstName = getSponsorData?.userDetails?.firstName;
        settings.landingSponsorLastName = getSponsorData?.userDetails?.lastName;
        settings.type = getSponsorData?.type;
      }
      if (
        localStorage.getItem('sponsorAllData') &&
        JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings
      ) {
        Object.assign(settings, JSON.parse(localStorage.getItem('sponsorAllData')).programSettings);
      }
      selectAllTabs();
      initialSettings.value = JSON.stringify(settings);
    });

    function saveSettings() {
      return new Promise((resolve, reject) => {
        isSaveSelectedProgramLoading.value = true;
        if (!settings.includeChangeTitleText) {
          const sponsorAllData = localStorage.getItem('sponsorAllData');
          if (sponsorAllData) {
            const getSponsorData = JSON.parse(sponsorAllData);
            settings.landingOrganizationName = getSponsorData?.name;
            settings.landingSponsorFirstName = getSponsorData?.userDetails?.firstName;
            settings.landingSponsorLastName = getSponsorData?.userDetails?.lastName;
          }
        }
        const API_ENDPOINT = `${process.env.VUE_APP_SPONSOR_SERVICE}/save-sponsor-setting`;
        const data = {
          shareCode: props.shareCode,
          programSettings: settings
        };
        axios
          .post(`${API_ENDPOINT}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          })
          .then(async res => {
            isSaveSelectedProgramLoading.value = false;
            const sponsorAllData = JSON.parse(localStorage.getItem('sponsorAllData'));
            sponsorAllData.programSettings = settings;
            localStorage.setItem('sponsorAllData', JSON.stringify(sponsorAllData));
            localStorage.setItem('programSettings', JSON.stringify(settings));
            ctx.emit('updateList', settings);
            resolve(res);
          })
          .catch(error => {
            isSaveSelectedProgramLoading.value = false;
            console.log(error);
            reject(error);
          });
      });
    }

    const saveSettingsAndPrograms = async () => {
      try {
        // Save settings
        // localStorage.setItem('programSettings', JSON.stringify(settings));
        // update settings
        await saveSettings();
        window.location.reload();

        // Save selected programs
        // await saveSelectedProgram();

        // Update initial settings to reset change detection
        initialSettings.value = JSON.stringify(settings);

        // Close the dialog
        showSettingsDialog.value = false;
      } catch (error) {
        console.error('Error saving settings and programs:', error);
      }
    };

    // Add watcher to handle self-creation program tab
    watch(
      () => settings.includeSelfCreationOfPrograms,
      newValue => {
        if (newValue && !settings.enabledTabs.includes('create-program')) {
          settings.enabledTabs.push('create-program');
        } else if (!newValue) {
          settings.enabledTabs = settings.enabledTabs.filter(tab => tab !== 'create-program');
        }
      }
    );

    // Update the watcher to only initialize if the array is empty
    watch(
      () => settings.filterProgramModels,
      newValue => {
        if (newValue && settings.selectedProgramModels.length === 0) {
          // Only initialize if no templates are selected
          settings.selectedProgramModels = sortedTemplates.value.map(t => t.templateName);
        }
      }
    );

    const openSettingsDialog = () => {
      showSettingsDialog.value = true;
    };

    const selectAllProgramModels = () => {
      settings.selectedProgramModels = sortedTemplates.value.map(t => t.templateName);
    };

    const deselectAllProgramModels = () => {
      settings.selectedProgramModels = [];
    };

    const selectOnlyProgramModel = (templateName: string) => {
      settings.selectedProgramModels = [templateName];
    };

    const selectAllPathways = () => {
      settings.selectedPathways = pathwaysList.value.map(p => p.name);
    };

    const deselectAllPathways = () => {
      settings.selectedPathways = [];
    };

    const selectOnlyPathway = (pathwayName: string) => {
      settings.selectedPathways = [pathwayName];
    };

    return {
      ...toRefs(state),
      toggleSidebar,
      isSearched,
      reloadPrograms,
      resetFilters,
      // loadMorePrograms,
      filterPathways,
      filteredPrograms,
      filterIndustry,
      searchProgram,
      bookmarkProgram,
      currentUnit,
      snackbar: true,
      skip,
      limit,
      isFetching,
      allProgramsFetched,
      categories,
      templates,
      currentTemplate,
      colors,
      selectedPathways,
      pathwaysList,
      isSearching,
      sidebar,
      getAllPrograms,
      selectedIndustries,
      isSidebar,
      loadTemplate,
      isAddClass,
      search,
      resetSearch,
      updateSelectedEmployerList,
      saveSelectedProgram,
      isSaveSelectedProgramLoading,
      getTemplateIcon,
      getTemplateColor,
      sortedTemplates,
      getTemplateDuration,
      updateURLWithPathways,
      showSettingsDialog,
      settings,
      openSettingsDialog,
      saveSettingsAndPrograms,
      selectAllProgramModels,
      deselectAllProgramModels,
      selectOnlyProgramModel,
      selectAllPathways,
      deselectAllPathways,
      selectOnlyPathway,
      selectAllTabs,
      deselectAllTabs,
      hasUnsavedChanges,
      templatesToShow,
      existingEmployerDomains
    };
  }
});
