










































































































































import { ref, computed, defineComponent, PropType, watch } from '@vue/composition-api';
import ATextInput from '@/components/atoms/ATextInput.vue';
import { useProgramState } from '@/store';

export default defineComponent({
  name: 'Bar',
  components: {
    ATextInput
  },
  props: {
    programFilter: {
      required: true,
      type: String
    },
    pathwaysFilter: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    residenceOptions: {
      required: true,
      type: Array
    },
    searchProgram: {
      required: true,
      type: Function,
      default: () => {}
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    resetFilters: {
      type: Function,
      default: () => {}
    }
  },

  setup(props, ctx) {
    const { industryList }: any = useProgramState(['industryList']);
    const isSortByCount = ref(true);
    const industryListValue = computed(() => {
      if (isSortByCount.value) {
        return [...industryList.value].sort((a, b) => b.count - a.count);
      }
      return [...industryList.value].sort((a, b) => (a.name < b.name ? -1 : 1));
    });
    const vertical = ref(true);
    const expand = ref(true); // open or closed sidebar
    const search = ref('');
    const selectedIndustries = ref([]);

    function filterIndustry(val) {
      if (!selectedIndustries.value.map(i => i.name).includes(val.name)) {
        selectedIndustries.value = [];
        selectedIndustries.value.push(val);
        ctx.emit('filter-industry', selectedIndustries.value);
      } else {
        const index = selectedIndustries.value.findIndex(s => s === val);
        selectedIndustries.value.splice(index, 1);
        ctx.emit('filter-industry', selectedIndustries.value);
      }
    }

    function toggleSidebar() {
      expand.value = !expand.value;
      ctx.emit('toggle-sidebar', expand.value);
    }

    function resetSearch() {
      search.value = '';
    }
    function removeClass() {
      this.$el.parentNode.classList.remove('active');
      ctx.emit('removeClass', false);
    }
    const resetFiltersValue = () => {
      selectedIndustries.value = [];
      props.resetFilters();
    };

    watch(search, val => {
      if (val.length > 2) {
        props.searchProgram(val);
      }

      if (!val.length) {
        ctx.emit('reload-programs');
      }
    });
    const requestEmployerDialog = ref(false);
    return {
      requestEmployerDialog,
      expand,
      resetSearch,
      filterIndustry,
      search,
      vertical,
      industryList,
      industryListValue,
      selectedIndustries,
      toggleSidebar,
      resetFiltersValue,
      isSortByCount,
      removeClass
    };
  }
});
